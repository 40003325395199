import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CheckMark } from '../../../assets/icons/check.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../../containers/ScreenContainers';
import { getAllCampus } from '../../../redux/campus-management/actions';
import { capitalizeFirstLetter } from '../../../utils';

const CampusLinks = ({ organizationName, organizationLogo, staffCampus }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { allCampus } = useSelector((store) => store.campus);

    const campusId = sessionStorage.getItem('campusId');
    const filteredCampus = allCampus?.filter((item) => staffCampus?.includes(item?._id));

    const availableCampus = staffCampus?.length > 0 ? filteredCampus : allCampus;

    useEffect(() => {
        if (allCampus?.length === 0) {
            dispatch(getAllCampus());
        }
    }, [dispatch, allCampus]);

    const handleCampusClick = (campus) => {
        sessionStorage.setItem('campusId', campus?._id);
        navigate('/dashboard');
    };

    return (
        <FlexCentredCol className="py-[1.6rem]">
            <div className="px-[2.4rem]">
                <Text align="left" weight="500" size="1.4rem" lineHeight="1.7rem" bottom="8px" color="#6B7280">
                    Campus
                </Text>
            </div>
            <FlexCentredCol className="gap-[16px]">
                {availableCampus?.map((item, index) => (
                    <FlexCentredRow key={item?.campusName} className="gap-[16px] pb-[1.6rem]">
                        <div onClick={() => handleCampusClick(item)} className="px-[2.4rem]">
                            <FlexCentredRow className="gap-[8px]">
                                <Text
                                    align="left"
                                    weight="400"
                                    size="1.6rem"
                                    lineHeight="2.4rem"
                                    color="#6B7280"
                                >{`Campus ${index + 1}:`}</Text>
                                <Text align="left" weight="600" size="1.6rem" lineHeight="2.4rem" color="#6B7280">
                                    {item?.campusName}
                                </Text>
                            </FlexCentredRow>
                            <Text align="left" weight="400" size="1.2rem" lineHeight="1.7rem" color="#6B7280">
                                {organizationName}
                            </Text>
                        </div>
                        {item?._id === campusId && <CheckMark />}
                    </FlexCentredRow>
                ))}
            </FlexCentredCol>
            <FlexCentredCol className="gap-[8px] border-y-[0.5px] border-y-[#6B7280] px-[2.4rem] py-[1.6rem]">
                <Text align="left" weight="500" size="1.4rem" lineHeight="1.7rem" color="#6B7280">
                    Organisation
                </Text>
                <FlexCentredRow className="gap-[8px]" onClick={() => navigate('/campus-management')}>
                    {organizationLogo ? (
                        <img src={organizationLogo} alt="" className="w-[4rem] h-[4rem] rounded-full" />
                    ) : (
                        <div className="flex items-center justify-center w-[4rem] h-[4rem] rounded-full bg-[#4338CA]">
                            <Text size="2rem" align="center" weight="400" color="#fff">
                                {capitalizeFirstLetter(organizationName)?.charAt(0)}
                            </Text>
                        </div>
                    )}
                    <Text align="left" weight="400" size="1.6rem" lineHeight="2.4rem" color="#1F2937">
                        {organizationName}
                    </Text>
                </FlexCentredRow>
            </FlexCentredCol>
        </FlexCentredCol>
    );
};

export default CampusLinks;
