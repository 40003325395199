import React from 'react';

import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as StaffIcon } from '../../assets/icons/landmark.svg';
import { Card } from '../../components';
import { LoadingButton } from '../../components/buttons';
import { Text } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexColumn, FlexDiv } from '../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../utils';

const StaffArena = ({ staffName, staffAppointment, staffEmail, staffImage }) => {
    const navigate = useNavigate();
    const staffDetails = [
        { staffKey: 'Email Address', staffValue: staffEmail },
        { staffKey: 'Designation', staffValue: capitalizeFirstLetter(staffAppointment) },
    ];
    return (
        <Card className="rounded-[10px] relative">
            <FlexDiv className="gap-3">
                <StaffIcon />
                <Text size="1.6rem" weight="600">
                    Staff Arena
                </Text>
            </FlexDiv>
            <div className="pt-3">
                <FlexColumn className="justify-between">
                    <FlexColumn className="gap-3">
                        <FlexCentredRow className="gap-[12px]">
                            {staffImage ? (
                                <img src={staffImage} alt="" className="rounded-full w-[82px] h-[82px]" />
                            ) : (
                                <Avatar textSizeRatio={1.75} className="rounded-full" name={staffName} />
                            )}
                            <FlexColumn>
                                <Text align="left" weight="500" size="1.2rem" color="#9CA3AF">
                                    Name
                                </Text>
                                <Text align="left" weight="500" size="1.4rem" color="#374151">
                                    {staffName}
                                </Text>
                            </FlexColumn>
                        </FlexCentredRow>
                        {staffDetails?.map((item) => (
                            <FlexColumn key={item?.staffKey} className="border-b border-[#E5E7EB]">
                                <Text align="left" weight="500" size="1.2rem" color="#9CA3AF">
                                    {item?.staffKey}
                                </Text>
                                <Text align="left" weight="500" size="1.4rem" color="#374151">
                                    {item?.staffValue}
                                </Text>
                            </FlexColumn>
                        ))}
                    </FlexColumn>
                    <div className="absolute bottom-0 left-0 right-0 p-[1.6rem]">
                        <LoadingButton
                            className="w-full"
                            onClick={() => navigate('/staff-arena/communications/received')}
                        >
                            Open Staff Arena
                        </LoadingButton>
                    </div>
                </FlexColumn>
            </div>
        </Card>
    );
};

export default StaffArena;
