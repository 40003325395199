import React, { useEffect, useState, useMemo } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down-white.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-red.svg';
import { ActionsPopup, ExportSheetDialogue } from '../../../../components';
import { Button } from '../../../../components/buttons';
import CardPopup from '../../../../components/popups/card-popup';
// import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
import { FlexCentredRow, FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import { useTableContext } from '../../../../context/tableContext';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { attachStudentToFee, getUnpaidFull, removeStudentFromFee } from '../../../../redux/bursary/actions';
import { getStudentDetails } from '../../../../redux/students/actions';
import currencyFormatter from '../../../../utils/formatCurrency';
import AttachStudentToFee from '../bursary-student-details/attach-student';
import PaymentDetailsModal from '../payment-modal';

const StyledButton = tw.button`
h-[40px]
px-[16px]
py-[8px]
bg-[#6366F1]
text-white
text-[14px]
font-medium
`;

const TableButton = styled(StyledButton)`
    outline: none !important;
`;

const NotPaidStudents = ({ unpaidStudents, loading, currentFee, setUnpaidStudents, id }) => {
    const dispatch = useDispatch();
    const { bioData, basicData } = useSelector((store) => store.student.studentDetails);
    const { unpaidStudents: responseData } = useSelector((store) => store.bursary);
    const { totalPages, totalNoOfStudents } = responseData || {};
    const {
        firstDegreeOptionsStr,
        secondDegreeOptionsStr,
        //  firstDegreeOptionsArray = [],
    } = useSelector((state) => state.academicStructure);
    const { students } = useSelector((store) => store.student);
    const { id: feeId } = useParams();

    const { tableSettings } = useTableContext();
    const { updateInfo } = bioData || {};
    const numOfPages = tableSettings?.numOfPages;

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [displayPaymentDetails, setDisplayPaymentDetails] = useState(false);
    const [studentId, setStudentId] = useState('');
    const [studentFee, setStudentFee] = useState([]);
    const [currentPage, setCurrentPage] = useState('1');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [openModal, setOpenModal] = useState();
    const [refetch, setRefetch] = useState(false);

    const handleRowClick = (item) => {
        setStudentId(item._id);
        setStudentFee([item]);
        setDisplayPaymentDetails(true);
    };

    const fields = [
        {
            header: 'Names',
            accessorKey: 'name',
            cell: ({ row }) => (
                <p>
                    {row.original?.lastName} {row.original?.firstName} {row.original?.middleName || ''}
                </p>
            ),
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Matric No',
            accessorKey: 'matricNumber',
        },
        {
            header: firstDegreeOptionsStr,
            accessorKey: 'faculty',
            cell: ({ row }) => <p>{row.original?.college || row.original?.faculty || row.original?.school}</p>,
        },
        {
            header: secondDegreeOptionsStr,
            accessorKey: 'program',
            cell: ({ row }) => <p>{row.original?.program || row.original?.department}</p>,
        },
        {
            cell: (props) => <p>{currencyFormatter.format(props.getValue() || 0)}</p>,
            header: 'Amount Payable',
            accessorKey: 'amountPayable',
        },
        {
            header: '',
            accessorKey: 'view',
            cell: ({ row }) => (
                <Button border="1px solid #1F2937" onClick={() => handleRowClick(row.original)}>
                    View
                </Button>
            ),
        },
    ];

    const handleAttachStudents = async () => {
        const payload = { studentIds: selectedStudents?.map((student) => student?._id) };
        const res = await dispatch(attachStudentToFee(payload, feeId));
        if (res) {
            setSelectedStudents([]);
            setRefetch(!refetch);
            setOpenModal(null);
        }
    };

    const handleRemoveStudent = async () => {
        const payload = { studentIds: selectedStudents?.map((student) => student?._id) };
        const res = await dispatch(removeStudentFromFee(payload, feeId));
        if (res) {
            setSelectedStudents([]);
            setRefetch(!refetch);
            setOpenModal(null);
        }
    };

    const acceptedStudents = useMemo(() => students?.filter((student) => student?.status === 'Accepted'), [students]);

    const filte = useMemo(
        () => acceptedStudents?.filter((item) => !unpaidStudents?.some((stud) => stud?._id === item?._id)),
        [acceptedStudents, unpaidStudents],
    );

    useEffect(() => {
        if (studentId) {
            dispatch(getStudentDetails(studentId));
        }
    }, [dispatch, studentId]);

    useEffect(() => {
        const queries = {
            search: debouncedSearchValue,
            page: currentPage,
            limit: numOfPages,
        };
        if (id) {
            const getUnpaidStudents = async () => {
                const res = await dispatch(getUnpaidFull(id, queries));
                setUnpaidStudents(res);
            };
            getUnpaidStudents();
        }
    }, [id, debouncedSearchValue, currentPage, refetch, dispatch, setUnpaidStudents, numOfPages]);

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={unpaidStudents || []}
                fields={fields}
                loading={loading}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setSelectedStudents([items[0]])}
                // onRowClick={(item) => {
                //     const selectedItem = unpaidStudents?.find((stud) => stud?._id === item?._id);
                //     handleRowClick(selectedItem);
                // }}
                isPaginated={true}
                paginationComponent={
                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                        <PaginationStatus
                            currentPage={currentPage}
                            itemsPerPage={tableSettings?.numOfPages}
                            totalItems={totalNoOfStudents}
                        />
                        <Pagination
                            pageRangeDisplayed={3}
                            totalPages={totalPages}
                            onPageChange={(nextPage) => {
                                setCurrentPage(nextPage);
                            }}
                            currentPage={currentPage}
                        />
                    </FlexRowSpaceBetween>
                }
                tableUtilsChildren={
                    currentFee?.fee?.feeType?.toLowerCase() === 'direct-student' && (
                        <RelativeContainer>
                            <FlexCentredRow className="gap-[0.05rem]">
                                <TableButton
                                    onClick={() => setOpenModal('open-paid-attach')}
                                    className="rounded-l-[8px]"
                                >
                                    Add Student
                                </TableButton>
                                <TableButton onClick={() => setOpenModal('open-dropdown')} className="rounded-r-[8px]">
                                    <ArrowDown />
                                </TableButton>
                            </FlexCentredRow>
                            <ActionsPopup
                                open={openModal === 'open-dropdown'}
                                close={() => setOpenModal(null)}
                                items={[
                                    {
                                        icon: <DeleteIcon />,
                                        name: 'Remove Student From Fee',
                                        click: () => setOpenModal('open-paid-remove'),
                                        disabled: !unpaidStudents?.length > 0,
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    )
                }
            />

            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                // chooseAction={} exportAction={}
            />
            <CardPopup show={displayPaymentDetails} close={() => setDisplayPaymentDetails(false)} noClose={true}>
                <PaymentDetailsModal
                    basicData={basicData}
                    updateInfo={updateInfo}
                    studentFee={studentFee}
                    session={currentFee}
                />
            </CardPopup>
            <AttachStudentToFee
                title="Add Students to Fee"
                acceptedStudents={filte}
                subtitle2="To confirm, enter add student in the text field."
                value={inputValue}
                setInputValue={setInputValue}
                close={() => {
                    setSelectedStudents([]);
                    setOpenModal('');
                }}
                btnText="Add Student"
                placeholder="Enter add student"
                onConfirm={handleAttachStudents}
                setSelectedStudents={setSelectedStudents}
                disabled={inputValue?.toLowerCase() !== 'add student'}
                list={selectedStudents}
                show={openModal === 'open-paid-attach'}
                selectedStudents={selectedStudents}
            />

            <AttachStudentToFee
                show={openModal === 'open-paid-remove'}
                title="Remove Student"
                subtitle="The selected student will be removed permanently."
                subtitle2="To confirm, enter remove in the text field."
                value={inputValue}
                setInputValue={setInputValue}
                close={() => {
                    setSelectedStudents([]);
                    setOpenModal('');
                }}
                btnText="Yes, Remove"
                placeholder="Input remove"
                onConfirm={handleRemoveStudent}
                list={selectedStudents?.map((item) => {
                    return {
                        ...item,
                        name: `${item?.firstName || ''} ${item?.lastName || ''} ${item?.middleName || ''}`,
                    };
                })}
                // isLoading={studentsLoading}
                disabled={inputValue?.toLowerCase() !== 'remove'}
                isSelect={false}
                bgColor="#EF4444"
            />
        </>
    );
};

export default NotPaidStudents;
