export const campusNavLinks = [
    {
        title: 'Campuses',
        to: '/campus-management',
    },
    {
        title: 'Access Manager',
        to: '/campus-management/access',
    },
    {
        title: 'Organization Settings',
        to: '/campus-management/settings',
    },
];

export const campusRoutes = campusNavLinks.map((item) => item.to);
