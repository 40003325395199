import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as PlusCircle } from '../../../assets/icons/circlePlus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as PlusWhite } from '../../../assets/icons/plus-white.svg';
import { ConfirmActionDialogue } from '../../../components';
import { Button } from '../../../components/buttons';
import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer, FlexCentredRow } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { deleteAgent, getAllAgents } from '../../../redux/bursary/actions';
import { storeUnit } from '../../../utils/originUnit';

const fields = [
    {
        header: 'Company Name',
        accessorKey: 'companyName',
        cell: (props) => (
            <Link style={{ color: '#2563EB' }} to={props.row.original._id} state={{ agentDetails: props.row.original }}>
                {props.getValue()}
            </Link>
        ),
    },
    {
        header: 'Company Email',
        accessorKey: 'companyEmail',
        cell: (props) => <p>{props?.getValue()?.toLowerCase()}</p>,
    },
    {
        cell: (props) => (
            <p>{`${new Date(props?.getValue()).toLocaleDateString()}, ${new Date(
                props?.getValue(),
            ).toLocaleTimeString()}`}</p>
        ),
        header: 'Created At',
        accessorKey: 'createdAt',
    },
];

const AgentManagement = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { allAgents, isLoading } = useSelector((store) => store.bursary);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [selected, setSelected] = useState(null);

    const handleCloseModal = () => {
        setInputValue('');
        setSearchValue('');
        setOpenModal(null);
    };

    const handleDeleteAgent = async () => {
        const res = await dispatch(deleteAgent(selected._id));
        if (res) {
            setOpenModal(null);
            dispatch(getAllAgents());
        }
    };

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.search = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        dispatch(getAllAgents(query));
    }, [dispatch, query]);

    return (
        <div className="">
            <PageLayout
                pageTitle="Agents Management"
                secondaryButtonText="Add Agent"
                data={allAgents || []}
                fields={fields}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setSelected(items[0])}
                loading={isLoading}
                actionItems={[
                    {
                        icon: <PlusCircle fill="#1f2937" />,
                        name: 'Attach Fee',
                        click: () => navigate('attach-fee', { state: { agent: selected } }),
                        disabled: !selected,
                    },
                    {
                        icon: <EditIcon fill="#1f2937" />,
                        name: 'Edit Agent',
                        click: () => navigate('edit-agent', { state: { agent: selected } }),
                        disabled: !selected,
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Agent',
                        click: () => setOpenModal('delete-agent'),
                        disabled: !selected,
                    },
                ]}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                onSecondaryButtonClick={() => navigate('add')}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent
                            isInTable
                            title={searchValue ? '' : 'You have not created Agents yet!'}
                            subtitle={searchValue ? 'No agents exist that match your search' : 'Create Agent'}
                        >
                            {!searchValue && (
                                <FlexCentredRow className="gap-x-4">
                                    <Button bgColor="#6366F1" color="#fff" onClick={() => navigate('add')}>
                                        <PlusWhite />
                                        Add Agent
                                    </Button>
                                </FlexCentredRow>
                            )}
                        </ListEmptyContent>
                    </CenteredContainer>
                }
            />
            <ConfirmActionDialogue
                borderLine
                input
                isLoading={isLoading}
                placeholder="Input delete"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                show={openModal === 'delete-agent'}
                title="Delete Agent"
                close={handleCloseModal}
                subtitle="The selected Agent will be deleted permanently"
                bodyText="To confirm, enter delete in the text field."
                btn2Text="Yes, Delete"
                bgColor={'#ef4444'}
                confirmAction={handleDeleteAgent}
                btnDisabled={inputValue?.toLowerCase() !== 'delete'}
            />
        </div>
    );
};

export default AgentManagement;
