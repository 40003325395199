import { lazy } from 'react';

import EditLectureVenue from '../components/forms/edit-lecture-venue';

import Academics from './academics/academics';
import Access from './access';
import Administrator from './administrator';
import AddAdministrator from './administrator/add-administrator';
import EditAdministrator from './administrator/edit-administrator';
import CreateStudent from './admission/add-student';
import AdmissionDetails from './admission/admission-details';
import Admissions from './admission/admissions';
import AgentProfile from './admission/agent-profile';
import AgentDetails from './admission/agent-profile/agent-details';
import AdmissionCandidateDetails from './admission/candidate-details';
import EditStudent from './admission/edit-student';
import AdmissionSettings from './admission/setting';
import MatricNumberSettings from './admission/setting/matric-number';
import {
    ForgotPassword,
    ConfirmPassword,
    AdminCreatePassword,
    StaffSignIn,
    Onboarding,
    StaffCreatePassword,
    AdminSignIn,
} from './auth';
import Bursary from './bursary';
import AgentManagement from './bursary/agent-management';
import AddAgent from './bursary/agent-management/add-agent';
import AttachFee from './bursary/agent-management/attach-fee';
import EditAgent from './bursary/agent-management/edit-agent';
import ViewAgent from './bursary/agent-management/view-agent';
import Billing from './bursary/billing';
import AddSeat from './bursary/billing/addseat';
import InvoiceHistory from './bursary/billing/invoice-history';
import BillingPaymentHistory from './bursary/billing/payment-history';
import EditBillingInfo from './bursary/billing/payment-history/edit-billing-info';
import PaymentMethod from './bursary/billing/payment-method';
import EditPaymentMethod from './bursary/billing/payment-method/edit-payment-method';
import RemoveSeat from './bursary/billing/remove-seat';
import BillingVerification from './bursary/billing/verification-page';
import DebtorsList from './bursary/debt-management';
import AddDebtor from './bursary/debt-management/add-debtor';
import DebtorDetails from './bursary/debt-management/details';
import EditDebtor from './bursary/debt-management/edit-debtor';
import AddFee from './bursary/fees/add-fee';
import BursaryStudentDetails from './bursary/fees/bursary-student-details';
import CreateFee from './bursary/fees/create-fee';
import EditFee from './bursary/fees/edit-fee';
import FeeDetails from './bursary/fees/fee-details';
import BursarySettings from './bursary/settings';
import PaymentGateway from './bursary/settings/payment-gateway';
import BursaryStaffDetails from './bursary/staff/staff-details';
import CampusManagement from './campus-management';
import AccessManager from './campus-management/access-manager';
import EditOrganizationStaff from './campus-management/access-manager/edit-org-staff';
import InviteOrganizationStaff from './campus-management/access-manager/invite-org-staff';
import AddCampus from './campus-management/add-campus';
import EditCampus from './campus-management/edit-campus';
import { Received, ComposeCommunications, CommsSettings, ComposeForm, ViewCommunications } from './communication';
import Configuration from './configuration';
import AcademicConfiguration from './configuration/academic';
import AcademicCalendar from './configuration/academic-calendar';
import CreateAcademicCalendar from './configuration/academic-calendar/create-academic-calendar';
import CreateActivityInWeek from './configuration/academic-calendar/create-activity';
import EditAcademicCalendar from './configuration/academic-calendar/edit-academic-calendar';
import DistributionList from './configuration/distribution-lists';
import AddDistributionList from './configuration/distribution-lists/add-distribution-list';
import UpdateDistributionList from './configuration/distribution-lists/update-distribution-list';
import GeneralInformation from './configuration/general-information';
import SpecialLetters from './configuration/special-letters';
import SpecialLetterCompose from './configuration/special-letters/compose';
import Delete from './configuration/special-letters/delete';
import Edit from './configuration/special-letters/edit';
import EditSpecialLetterCompose from './configuration/special-letters/edit/editCompose';
import Upload from './configuration/special-letters/upload';
import View from './configuration/special-letters/view';
import ExamsAndRecords from './exams-records';
import ExamsRecordsDepartments from './exams-records/departments';
import ManualPromotion from './exams-records/manual-promotion';
import ResultsBacklog from './exams-records/results-backlog';
import UploadResultsBacklog from './exams-records/results-backlog/upload';
import ExamsRecordsStudentDetails from './exams-records/student-details';
import ExamsRecordsStudents from './exams-records/students';
import FacultyCourses from './faculty/courses';
import AddFacultyGeneralCourse from './faculty/courses/add-new';
import FacultyDepartments from './faculty/departments';
import DepartmentAdministrators from './faculty/departments/administrator';
import AddDepartmentAdministrators from './faculty/departments/administrator/add-administrator';
import EditDepartmentAdministrator from './faculty/departments/administrator/edit-administrator';
import CourseAllocation from './faculty/departments/course-allocation';
import AllotedHistory from './faculty/departments/course-allocation/allocation-history';
import CourseDetails from './faculty/departments/course-details';
import DepartmentCourses from './faculty/departments/courses';
import AddNewCourse from './faculty/departments/courses/add-new';
import EditNewCourse from './faculty/departments/courses/edit-course';
import LectureVenue from './faculty/departments/lecture-venues';
import DepartmentPersonnel from './faculty/departments/personnel';
import DepartmentProgrammes from './faculty/departments/programmes';
import AddNewProgramme from './faculty/departments/programmes/add-new';
import CourseFormManagement from './faculty/departments/programmes/course-form-magt';
import CourseFormMagtDetails from './faculty/departments/programmes/course-form-magt/details';
import { CreateCurriculum, CurriculumDetails, CurriculumLevels } from './faculty/departments/programmes/curriculum';
import ProgrammeStudents from './faculty/departments/programmes/students';
import DepartmentRoles from './faculty/departments/roles';
import CreateDepartmentRole from './faculty/departments/roles/create-role';
import EditDepartmentRole from './faculty/departments/roles/edit-role';
import Settings from './faculty/departments/settings';
import AcademicStanding from './faculty/departments/settings/academic-standing';
import CumulativeCreditUnits from './faculty/departments/settings/cumulative-credit-units';
import MinimumPromotionCGPA from './faculty/departments/settings/minimum-promotion';
import ProbationListSettings from './faculty/departments/settings/probation-list';
import WithdrawalListSettings from './faculty/departments/settings/withdrawal-list';
import StudentDetail from './faculty/departments/student-details';
import DepartmentStudents from './faculty/departments/students';
import TimeTable from './faculty/departments/timetable';
import ExamTimetable from './faculty/exam-timetable';
import Faculties from './faculty/faculties';
import LectureTimetable from './faculty/lecture-timetable';
import FacultyPersonnels from './faculty/personnel';
import FacultyStudents from './faculty/students';
import NewHostel from './hostel/add-new-hostel';
import HostelManagement from './hostel/hostel-management';
import AuditLog from './ict/audit-log';
import Home from './ict/home';
import OrganizationSettings from './ict/organization-settings';
import ContactInformation from './ict/organization-settings/contact-info';
import ICTSettings from './ict/settings';
import AddAdmin from './ict/settings/add-admin';
import AdminProceed from './ict/settings/add-admin/admin-proceed';
import AddLecturer from './ict/settings/add-lecturer';
import LecturersProceed from './ict/settings/add-lecturer/lecturer-proceed';
import AddStudent from './ict/settings/add-students';
import StudentProceed from './ict/settings/add-students/student-proceed';
import CustomDomain from './ict/settings/custom-domain';
import Domain from './ict/settings/domain';
import OrganizationAdmins from './organization-admins';
import AddOrgAdministrator from './organization-admins/add-administrator';
import IctOrganizationAdmins from './organization-admins/ict-admins';
import OrganizationUnit from './organization-unit';
import OrganizationUnitDetails from './organization-unit/details';
import Appointment from './personnel/appointment';
import AppointmentDetails from './personnel/appointment/appointmentDetails';
import Designations from './personnel/designation';
import DesignationDetails from './personnel/designation/details';
import StaffManagement from './personnel/staff-access';
import CreateStaff from './personnel/staff-access/add-staff';
import StaffDetails from './personnel/staff-access/details';
import EditStaff from './personnel/staff-access/edit-staff';
import Records from './records';
import RecordsDetails from './records/details';
import {
    Broadsheet,
    ExamLevels,
    SummarySheet,
    WithdrawalListSheet,
    ProbationListSheet,
    IncompleteResult,
    ResultProgrammesList,
} from './results';
import ProgrammeResults from './results/department/results';
import Roles from './roles';
import AssignRole from './roles/assign-role';
import CreateRole from './roles/create-role';
import EditRole from './roles/edit-role';
import GeneralSettings from './setting';
import DepartmentSettings from './setting/department';
import FacultySettings from './setting/faculty';
import ClassOfDegreeSetting from './setting/faculty/class-of-degree-settings';
import GradeSetting from './setting/faculty/grade-settings';
import WeightedGradePointSetting from './setting/faculty/weighted-grade-point';
import Staff from './staff';
import StaffDetail from './staff/details';
import { StaffComposeLetter, StaffComposeMail, StaffReceived, StaffSent } from './stafff-arena/communications';
import StaffArenaDraft from './stafff-arena/communications/draft';
import StaffLetterPreview from './stafff-arena/communications/sent/preview/letter';
import StaffDirectory from './stafff-arena/directory';
import Preference from './stafff-arena/preference';
import ChangePassword from './stafff-arena/preference/change-password';
import SharedFiles from './stafff-arena/shared-files';
import ViewFiles from './stafff-arena/shared-files/view';
const EdtProfile = lazy(() => import('./stafff-arena/edit-profile'));

export {
    ManualPromotion,
    UploadResultsBacklog,
    ResultsBacklog,
    DebtorDetails,
    DebtorsList,
    AddDebtor,
    WeightedGradePointSetting,
    FacultySettings,
    IctOrganizationAdmins,
    AssignRole,
    DepartmentSettings,
    Academics,
    AddFacultyGeneralCourse,
    EditDepartmentAdministrator,
    EditLectureVenue,
    SummarySheet,
    IncompleteResult,
    ProbationListSheet,
    WithdrawalListSheet,
    ExamLevels,
    Broadsheet,
    AllotedHistory,
    MinimumPromotionCGPA,
    EditStudent,
    DepartmentAdministrators,
    AddDepartmentAdministrators,
    EditDepartmentRole,
    CreateDepartmentRole,
    DepartmentRoles,
    StaffDirectory,
    MatricNumberSettings,
    CourseFormMagtDetails,
    CourseFormManagement,
    CreateCurriculum,
    CurriculumDetails,
    EditRole,
    AddOrgAdministrator,
    Settings,
    StaffDetail,
    OrganizationAdmins,
    CustomDomain,
    Domain,
    AddAdmin,
    AddStudent,
    AddLecturer,
    StudentProceed,
    LecturersProceed,
    AdminProceed,
    DesignationDetails,
    Designations,
    OrganizationUnitDetails,
    OrganizationUnit,
    Records,
    RecordsDetails,
    Admissions,
    AdmissionDetails,
    AdmissionCandidateDetails,
    AdmissionSettings,
    Faculties,
    ExamsAndRecords,
    ExamsRecordsDepartments,
    ExamsRecordsStudents,
    ExamsRecordsStudentDetails,
    ConfirmPassword,
    ForgotPassword,
    Onboarding,
    AdminCreatePassword,
    StaffCreatePassword,
    StaffSignIn,
    AdminSignIn,
    Configuration,
    GeneralInformation,
    AcademicConfiguration,
    HostelManagement,
    NewHostel,
    StaffManagement,
    StaffDetails,
    Bursary,
    BursaryStaffDetails,
    FeeDetails,
    BursaryStudentDetails,
    AddFee,
    FacultyCourses,
    FacultyDepartments,
    CourseAllocation,
    DepartmentCourses,
    DepartmentPersonnel,
    StudentDetail,
    DepartmentStudents,
    FacultyPersonnels,
    FacultyStudents,
    SpecialLetters,
    Edit,
    View,
    Upload,
    Delete,
    LectureTimetable,
    ExamTimetable,
    AcademicCalendar,
    TimeTable,
    CreateAcademicCalendar,
    EditAcademicCalendar,
    CreateFee,
    Staff,
    CreateStudent,
    CreateActivityInWeek,
    CreateStaff,
    EditStaff,
    Roles,
    CreateRole,
    Administrator,
    AddAdministrator,
    EditAdministrator,
    Received,
    LectureVenue,
    CourseDetails,
    AddNewCourse,
    EditNewCourse,
    DistributionList,
    GeneralSettings,
    AddDistributionList,
    CurriculumLevels,
    BursarySettings,
    PaymentGateway,
    UpdateDistributionList,
    ClassOfDegreeSetting,
    GradeSetting,
    Appointment,
    AppointmentDetails,
    WithdrawalListSettings,
    ProbationListSettings,
    AcademicStanding,
    AuditLog,
    CumulativeCreditUnits,
    DepartmentProgrammes,
    AddNewProgramme,
    ProgrammeStudents,
    EdtProfile,
    Preference,
    ChangePassword,
    SpecialLetterCompose,
    EditSpecialLetterCompose,
    StaffReceived,
    StaffSent,
    StaffComposeMail,
    StaffComposeLetter,
    SharedFiles,
    Home,
    ViewFiles,
    ICTSettings,
    OrganizationSettings,
    ContactInformation,
    StaffLetterPreview,
    EditFee,
    StaffArenaDraft,
    Billing,
    AddSeat,
    InvoiceHistory,
    BillingPaymentHistory,
    EditBillingInfo,
    PaymentMethod,
    EditPaymentMethod,
    RemoveSeat,
    ProgrammeResults,
    ResultProgrammesList,
    BillingVerification,
    Access,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
    AgentManagement,
    AddAgent,
    EditAgent,
    AttachFee,
    ViewAgent,
    AgentProfile,
    AgentDetails,
    EditDebtor,
    CampusManagement,
    AddCampus,
    EditCampus,
    AccessManager,
    InviteOrganizationStaff,
    EditOrganizationStaff,
};
