import { Suspense, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import { Loader } from './components/loader';
import Layout from './layout';
// import { logoutUser } from './redux/user/actions';
import { routes } from './routes';
import { parseJSON } from './utils/useJSON';

import 'react-datepicker/dist/react-datepicker.css';

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { pathname } = location || {};
    const token = parseJSON('token', '');

    const { userData, staffData } = useSelector((state) => state.user);

    const isAuthenticated = token ? true : false;

    const userType = staffData?.userType || userData?.userType;
    const savedCampusId = sessionStorage.getItem('campusId');
    const newCampusId = staffData?.campusId?.[0];

    useEffect(() => {
        // TODO is this useffect still needed with token in session storage?
        // 
        if (savedCampusId || !token) return;

        if (userType === 'staff' && newCampusId) {
            sessionStorage.setItem('campusId', newCampusId);
        } else if (!pathname?.includes('campus-management')) {
            navigate('/campus-management');
        }
    }, [navigate, newCampusId, savedCampusId, token, userType, pathname]);

    useEffect(() => {
        if (!token) {
            // dispatch(logoutUser(navigate));
            // navigate('/sign-in-staff');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, token]);

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                {routes.map((route) => (
                    <Route
                        key={route.path}
                        path={isAuthenticated ? '/' : route.path}
                        element={isAuthenticated ? <Navigate to="/dashboard" replace /> : route.element}
                    />
                ))}

                <Route path="*" element={<Layout />} />
            </Routes>
        </Suspense>
    );
}

export default App;
